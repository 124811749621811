(function ($) {
  site.template = site.template || {};
  const containersClass = '.product-sku-price';

  const renderPrice = ($container, html) => {
    $container
    .fadeTo('fast', 0, 'linear', function () {
      $container.html(html);
    })
    .fadeTo('fast', 1, 'linear');
  };

  $(document).on('product.skuSelect', '.js-product', function (e, skuBaseId) { 
    const $product = $(this);
    const product_sku_price = site.template.get({
      name: 'product_sku_price',
      data: prodcat.data.getSku(skuBaseId) // Only parse the sku because if sku is 0 then the price will be rendered empty
    });
    const html = $(product_sku_price).html();
    const $container = $(containersClass, $product);
    renderPrice($container, html)
  });
})(jQuery);
